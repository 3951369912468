<template>
    <v-row>
        <v-col>
            <div class="tw-flex tw-items-center tw-gap-x-2">
                <v-icon class="tw-rounded-md tw-bg-secondary tw-bg-opacity-100 tw-p-5 tw-text-white">mdi-engine</v-icon>
                <p class="tw-text-base tw-font-semibold">{{ matrix.process }} <span class="tw-font-normal tw-text-sm tw-pl-2">{{ $t('dashboards.matrix.labels.start') }}: {{matrix.startTime}} | {{ $t('dashboards.matrix.labels.end') }}: {{matrix.endTime}}</span></p>
            </div>
        </v-col>
        <v-col>
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <v-icon
                    class="tw-text-xl"
                    @click="handleExpandedState"
                    >{{ matrix.expanded == false ? 'mdi-arrow-expand' : 'mdi-arrow-collapse' }}</v-icon
                >
                <v-icon @click="removeMatrix">mdi-close</v-icon>
            </div>
        </v-col>
    </v-row>
    <!-- First Row -->
    <v-row>
        <v-col cols="2">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Indic.</p>
            </div>
        </v-col>
        <v-col>
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p
                    v-for="i in 7"
                    class="tw-w-full tw-text-center">
                    {{ i }}
                </p>
            </div>
        </v-col>
        <v-col cols="2">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Alertas</p>
            </div>
        </v-col>
    </v-row>
    <!-- Next Rows (based on the number of indicators)-->
    <v-row class="tw-mt-0">
        <v-col cols="2">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-center tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Água</p>
            </div>
        </v-col>
        <v-col>
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-gap-x-1 tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p
                    v-for="i in 7"
                    class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-sm"
                    :class="generateRandomColor()">
                     <!-- <span v-if="!matrix.expanded">&nbsp;</span>
                    <span v-if="matrix.expanded"><p class="tw-text-[10px] tw-text-white">00:00</p></span> -->
                    <span><p class="tw-text-[10px] tw-text-white">00:00</p></span>
                </p>
            </div>
        </v-col>
        <v-col cols="2">
            <div class="tw-flex tw-h-7 tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-text-ellipsis tw-rounded-md tw-bg-gray-200 tw-text-xs">
                <div class="tw-flex tw-gap-x-1">
                    <p class="tw-rounded-sm tw-bg-red-500 tw-p-[.125rem] tw-text-center tw-text-white tw-opacity-90">00</p>
                    <p class="tw-rounded-sm tw-bg-white tw-p-[.125rem] tw-text-center tw-text-gray-600">00:00</p>
                </div>
            </div>
        </v-col>
    </v-row>
    <v-row class="tw-mt-0">
        <v-col
            cols="2"
            class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-center tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Energia</p>
            </div>
        </v-col>
        <v-col class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-gap-x-1 tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p
                    v-for="i in 7"
                    class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-sm"
                    :class="generateRandomColor()">
                     <!-- <span v-if="!matrix.expanded">&nbsp;</span>
                    <span v-if="matrix.expanded"><p class="tw-text-[10px] tw-text-white">00:00</p></span> -->
                    <span><p class="tw-text-[10px] tw-text-white">00:00</p></span>
                </p>
            </div>
        </v-col>
        <v-col
            cols="2"
            class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-text-ellipsis tw-rounded-md tw-bg-gray-200 tw-text-xs">
                <div class="tw-flex tw-gap-x-1">
                    <p class="tw-rounded-sm tw-bg-green-500 tw-p-[.125rem] tw-text-center tw-text-white tw-opacity-90">00</p>
                    <p class="tw-rounded-sm tw-bg-white tw-p-[.125rem] tw-text-center tw-text-gray-600">00:00</p>
                </div>
            </div>
        </v-col>
    </v-row>
    <v-row class="tw-mt-0">
        <v-col
            cols="2"
            class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-center tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Temperatura</p>
            </div>
        </v-col>
        <v-col class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-gap-x-1 tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p
                    v-for="i in 7"
                    class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-sm"
                    :class="generateRandomColor()">
                    <!-- <span v-if="!matrix.expanded">&nbsp;</span>
                    <span v-if="matrix.expanded"><p class="tw-text-[10px] tw-text-white">00:00</p></span> -->
                    <span><p class="tw-text-[10px] tw-text-white">00:00</p></span>
                </p>
            </div>
        </v-col>
        <v-col
            cols="2"
            class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-text-ellipsis tw-rounded-md tw-bg-gray-200 tw-text-xs">
                <div class="tw-flex tw-gap-x-1">
                    <p class="tw-rounded-sm tw-bg-yellow-500 tw-p-[.125rem] tw-text-center tw-text-white tw-opacity-90">00</p>
                    <p class="tw-rounded-sm tw-bg-white tw-p-[.125rem] tw-text-center tw-text-gray-600">00:00</p>
                </div>
            </div>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <div class="tw-rounded-md tw-bg-gray-200">
                <div class="tw-flex tw-items-center tw-gap-x-2 tw-p-2">
                    <v-icon class="tw-rounded-md tw-bg-secondary tw-bg-opacity-100 tw-p-4 tw-text-white">mdi-chart-bar</v-icon>
                    <p>pH</p>
                </div>
                <Chart
                    class="chart"
                    type="bar"
                    :data="[4, 4.1, 4.3, 6.3, 6.4, 6.5, 6.6, 7.6, 7.7, 7.8, 7.9, 8.0, 8.1, 8.2, 8.3, 8.4, 8.5, 10.4, 10.5, 10.6, 11.5, 11.6, 11.7, 12]"
                    :xAxisInfo="[
                        '00:00',
                        '01:00',
                        '02:00',
                        '03:00',
                        '04:00',
                        '05:00',
                        '06:00',
                        '07:00',
                        '08:00',
                        '09:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                        '19:00',
                        '20:00',
                        '21:00',
                        '22:00',
                        '23:00',
                    ]"
                    series_name="kWh"
                    :series_color="primaryColor" />
            </div>
        </v-col>
        <!-- <div class="tw-flex tw-h-7 tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-text-ellipsis tw-rounded-md tw-bg-gray-200 tw-text-xs">
            <p>asdsaasd</p>
        </div> -->
    </v-row>
</template>
<script setup lang="ts">
    import Chart from '@/components/charts/Chart.vue';
    import { useAuthStore } from '@/store/auth';
    import { useI18n } from 'vue-i18n';

    const $t = useI18n().t;

    const primaryColor = useAuthStore().getActiveCompany?.primaryColor || '#1D70B7';

    const generateRandomColor = () => {
        const colors = ['tw-bg-red-400 tw-opacity-70', 'tw-bg-green tw-opacity-50', 'tw-bg-yellow tw-opacity-50'];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    const props = defineProps({
        matrix: {
            type: Object,
            required: true,
        },
    });
    const emit = defineEmits(['update:state', 'update:remove']);

    const handleExpandedState = () => {
        props.matrix.expanded = !props.matrix.expanded;
        emit('update:state', { ...props.matrix });
    };

    const removeMatrix = () => {
        emit('update:remove', props.matrix);
    };
</script>
