<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('dashboards.dashboard') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-row class="tw-py-4">
            <v-col
                v-for="matrix in matrixes"
                :key="matrix.id"
                :cols="12"
                :xl="matrix.expanded ? 12 : 6"
                :order="matrix.expanded ? 'first' : ''">
                <v-sheet class="pa-4 tw-rounded-xl">
                    <Matrix
                        :matrix="matrix"
                        @update:state="handleExpandedState"
                        @update:remove="removeMatrix" />
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>
<script setup lang="ts">
    import Matrix from '@/components/Matrix.vue';
    import { useAuthStore } from '@/store/auth';
    import { useI18n } from 'vue-i18n';
    import ioImg from '@/components/ioImg.vue';
    import { ref, computed } from 'vue';

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('dashboards.title'),
            },
            {
                title: $t('dashboards.matrix.title'),
                to: { name: 'MatrixDashboard' },
            },
        ];
    });

    const user = useAuthStore().user;

    //declare matrix data
    const matrixes = ref([
        {
            id: 1,
            process: 'OF 1525 | JET 1',
            startTime: '09:00',
            endTime: '13:25',
            expanded: false,
        },
        {
            id: 2,
            process: 'OF 1526 | JET 2',
            startTime: '09:00',
            endTime: '12:20',
            expanded: false,
        },
        {
            id: 3,
            process: 'OF 1527 | JET 3',
            startTime: '09:00',
            endTime: '12:20',
            expanded: false,
        },
        {
            id: 4,
            process: 'OF 1528 | JET 4',
            startTime: '09:00',
            endTime: '14:03',
            expanded: false,
        },
    ]);

    const handleExpandedState = (matrix: any) => {
        const index = matrixes.value.findIndex((m) => m.id === matrix.id);
        matrixes.value[index] = matrix;
    };

    const removeMatrix = (matrix: any) => {
        matrixes.value = matrixes.value.filter((m) => m.id !== matrix.id);
    };
</script>
