<template>
    <v-navigation-drawer
        permanent
        width="360"
        :rail="!isNavDrawerOpen"
        rail-width="80"
        :v-model="isNavDrawerOpen"
        color="primary">
        <div class="custom-scrollbar tw-flex tw-h-full tw-flex-col tw-justify-between">
            <div
                v-if="isNavDrawerOpen"
                class="tw-absolute tw-right-0 tw-m-1">
                <v-icon @click="isNavDrawerOpen = !isNavDrawerOpen">mdi-chevron-left</v-icon>
            </div>
            <div>
                <img
                    v-if="isNavDrawerOpen"
                    src="/logo-white-giatex.png"
                    alt=""
                    @click="$router.push({ name: 'Home' })"
                    class="tw-cursor-pointer tw-h-40 tw-pt-16 tw-pb-9 tw-mx-auto" />
                <v-icon
                    class="tw-m-4 tw-mx-auto tw-flex tw-text-4xl tw-text-white"
                    v-if="!isNavDrawerOpen"
                    @click="isNavDrawerOpen = !isNavDrawerOpen"
                    >mdi-menu</v-icon
                >
                <v-list
                    v-model:opened="open"
                    :class="{ 'custom-sidebar': !isNavDrawerOpen, 'tw-px-6': isNavDrawerOpen }"
                    class="tw-items-center tw-justify-center"
                    density="compact"
                    v-for="(item, i) in currentMenu"
                    :key="i">
                    <v-list-group
                        :value="item.id"
                        v-if="item.children">
                        <template v-slot:activator="{ props }">
                            <v-list-item
                                class="tw-rounded-lg"
                                v-bind="props"
                                :prepend-icon="item.icon"
                                :title="item.label"
                                :to="item.to"></v-list-item>
                        </template>
                        <div
                            v-for="(children, j) in item?.children"
                            :key="j">
                            <v-list-item v-if="children?.children">
                                <v-list-group :value="children.id">
                                    <template v-slot:activator="{ props }">
                                        <v-list-item
                                            class="tw-rounded-lg"
                                            v-bind="props"
                                            :prepend-icon="children.icon"
                                            :title="children.label"></v-list-item>
                                    </template>
                                    <div
                                        :class="{ 'tw-pl-7': isNavDrawerOpen }"
                                        v-for="(grandchildren, k) in children?.children"
                                        :key="k">
                                        <v-list-item
                                            class="tw-rounded-lg"
                                            :title="grandchildren.label"
                                            :prepend-icon="grandchildren.icon"
                                            :value="grandchildren.id"
                                            @click="onItemSelected(grandchildren)"></v-list-item>
                                    </div>
                                    <v-divider
                                        class="border-opacity-50"
                                        color="white"
                                        v-if="!isNavDrawerOpen"></v-divider>
                                </v-list-group>
                            </v-list-item>
                            <v-list-item v-else>
                                <v-list-item
                                    class="tw-rounded-lg"
                                    :title="children.label"
                                    :prepend-icon="children.icon"
                                    :value="children.id"
                                    @click="onItemSelected(children)"></v-list-item>
                            </v-list-item>
                        </div>
                        <v-divider
                            class="border-opacity-50"
                            color="white"
                            v-if="!isNavDrawerOpen && currentMenu.length - 1 !== i"></v-divider>
                    </v-list-group>
                    <v-list-item
                        class="tw-rounded-lg"
                        v-else
                        :title="item.label"
                        :prepend-icon="item.icon"
                        :to="item.to"></v-list-item>
                </v-list>
            </div>
            <div class="tw-mb-6">
                <v-card
                    v-if="isNavDrawerOpen"
                    variant="tonal"
                    class="tw-mx-8 tw-items-center tw-justify-center tw-py-6 tw-text-center">
                    <template v-slot:prepend>
                        <v-avatar size="60">
                            <io-img
                                format="thumbnail"
                                class="no-padding tw-rounded-full"
                                :media="selectedCompany?.image"
                                content-type="Media"></io-img>
                        </v-avatar>
                    </template>
                    <template v-slot:title>
                        <p class="tw-text-sm">
                            <v-select
                                label="Selecione a empresa"
                                flat
                                variant="solo"
                                bg-color="primary-300"
                                v-model="selectedCompany"
                                item-title="name"
                                item-value="id"
                                :items="companies"></v-select>
                        </p>
                    </template>
                </v-card>
            </div>
            <div>
                <v-list
                    class="tw-flex tw-items-center tw-justify-center tw-bg-secondary !tw-text-white"
                    bg-color="tw-text-primary">
                    <v-list-item @click="logout()">
                        <div class="tw-flex">
                            <v-list-item-icon>
                                <v-icon>mdi-logout</v-icon>
                            </v-list-item-icon>
                            <p
                                v-if="isNavDrawerOpen"
                                class="tw-ml-6">
                                Terminar Sessão
                            </p>
                        </div>
                    </v-list-item>
                </v-list>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { ref, watch, onBeforeUnmount, computed, onBeforeMount } from 'vue';
    import { useAppStore } from '../store/app';
    import { useAuthStore } from '@/store/auth';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import IoImg from '@/components/ioImg.vue';
    import { useMenu } from '@/configs/sidebar';
    import { Menu } from '../types';
    import { useDisplay } from 'vuetify';

    const $t = useI18n().t;

    const display = useDisplay();

    const appStore = useAppStore();
    const authStore = useAuthStore();
    const $router = useRouter();

    //open variable is used to handle collapse/expand of the sidebar
    const open = ref([]);

    const isNavDrawerOpen = ref(false);

    const itemSelected = ref<Menu | null>(null);

    const companies = ref(authStore.getUser?.companies || []);

    const menus = ref(authStore.getActiveCompany?.settings?.sidebarConfig?.menu || []);

    //remove string from label because its coming as string and we need to translate it
    menus.value.forEach((menu) => {
        menu.label = $t(menu.label);
        if (menu.children) {
            menu.children.forEach((submenu) => {
                submenu.label = $t(submenu.label);
                if (submenu.children) {
                    submenu.children.forEach((subsubmenu) => {
                        subsubmenu.label = $t(subsubmenu.label);
                    });
                }
            });
        }
    });

    // const isMobile = computed(() => {
    //     const { lgAndDown } = display;
    //     return lgAndDown.value;
    // });

    //this watch is used to handle the sidebar open/close
    watch(isNavDrawerOpen, (value) => {
        appStore.setIsSidebarOpen(value);
    });

    //this watch is used to handle the collapse/expand of the sidebar
    watch(open, (value) => {
        let findMenu = currentMenu.value.find((menu) => menu.id === value[0]);
        if (findMenu?.children) {
            let findSubMenu = findMenu.children.find((submenu) => submenu.id === value[1]);
            if (findSubMenu?.children) {
                //we check length 2 and 3, because length 2 is for submenu and length 3 is for subsubmenu
                if (value.length == 2) return;
                if (value.length == 3) {
                    let findSubSubMenu = findSubMenu.children.find((subsubmenu) => subsubmenu.id === value[2]);
                    if (findSubSubMenu?.children) {
                        return;
                    } else {
                        open.value = [value[0], value[2]];
                    }
                }
            } else {
                if (value.length == 2) open.value = [value[1]];
            }
        }
    });

    const selectedCompany = computed({
        get: () => authStore.getActiveCompany || null,
        set: (value) => {
            let c_ = companies.value.find((c) => c.id === value);
            authStore.setActiveCompany(c_ as any);
            $router.go(0);
        },
    });

    const currentMenu = computed(() => {
        if (!itemSelected.value) return menus.value;
        return itemSelected.value?.children || [];
    });

    function onItemSelected(item: Menu) {
        if (item?.children && item?.children.length > 0) {
            itemSelected.value = item;
        }
        if (item?.to) {
            $router.push(item.to);
        }
    }

    // onBeforeUnmount(() => {
    //     appStore.setIsSidebarOpen(false);
    // });

    async function logout() {
        authStore.$reset();
        console.log('aqui');
        $router.push({ name: 'Login' });
    }
</script>

<style>
    .v-list-group__items {
        --indent-padding: 0.3125rem !important;
    }
    .custom-sidebar .v-list-group__items .v-list-item {
        max-width: 65px;
        padding-right: 9px !important;
        padding-inline-start: calc(3px + var(--indent-padding)) !important;
    }
    .custom-sidebar {
        padding-left: 11.5px !important;
        max-width: 68px !important;
        /* margin-bottom: 10px !important; */
    }
</style>
