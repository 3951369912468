<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('industry.processes.title') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-row class="tw-justify-between tw-pt-4">
            <v-col
                class="tw-flex tw-gap-x-4"
                cols="12"
                lg="7">
                <v-text-field
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('shared.search')"
                    v-model="search">
                    <template #append-inner>
                        <v-icon class="tw-text-gray">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10 lg:tw-w-auto"
                    rounded="lg"
                    color="gray-300"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-black">mdi-filter</v-icon>
                    {{ $t('shared.filters') }}
                </v-btn>
            </v-col>
            <v-col
                class="tw-order-first tw-mb-2 lg:tw-order-last lg:tw-mb-0 lg:tw-flex lg:tw-justify-end"
                cols="12"
                md="auto">
                <!-- <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-mb-2 tw-mr-2 tw-h-10 xs:tw-mb-0"
                    rounded="lg"
                    color="primary">
                    <p class="tw-text-white">
                        {{ $t('industry.processes.labels.createOrder') }}
                    </p>
                </v-btn> -->
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10"
                    rounded="lg"
                    color="primary">
                    <p class="tw-text-white">
                        {{ $t('industry.processes.labels.createProcess') }}
                    </p>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                v-for="process in processes">
                <v-card
                    variant="flat"
                    class="mx-auto">
                    <v-list density="comfortable">
                        <v-list-group>
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props">
                                    <template v-slot:title>
                                        <v-list-item-title
                                            ><p class="tw-font-semibold">{{ process.name }}</p></v-list-item-title
                                        >
                                    </template>
                                </v-list-item>
                            </template>
                            <v-list-group
                                v-for="(stage, i) in process?.stages"
                                :key="i">
                                <template v-slot:activator="{ props }">
                                    <v-list-item
                                        class="tw-mb-2"
                                        @click="stage.expanded = !stage.expanded"
                                        :prepend-icon="stage.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                                        v-bind="props">
                                        <template v-slot:title>
                                            <v-row>
                                                <v-col>
                                                    <p class="tw-text-sm tw-font-medium tw-text-gray">{{ $t('industry.processes.labels.stage') }}</p>
                                                </v-col>
                                                <v-col>
                                                    <p class="tw-text-sm tw-font-medium tw-text-gray">{{ $t('industry.processes.labels.asset') }}</p>
                                                </v-col>
                                                <v-col>
                                                    <p class="tw-text-sm tw-font-medium tw-text-gray">{{ $t('industry.processes.labels.po') }}</p>
                                                </v-col>
                                                <v-col>
                                                    <p class="tw-text-sm tw-font-medium tw-text-gray">{{ $t('industry.processes.labels.startDate') }}</p>
                                                </v-col>
                                                <v-col>
                                                    <p class="tw-text-sm tw-font-medium tw-text-gray">{{ $t('industry.processes.labels.endDate') }}</p>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template v-slot:subtitle>
                                            <v-row>
                                                <v-col>
                                                    <p class="tw-text-base tw-font-semibold tw-text-black">{{ stage.stage }}</p>
                                                </v-col>
                                                <v-col>
                                                    <p class="tw-text-base tw-font-semibold tw-text-black">{{ stage.asset }}</p>
                                                </v-col>
                                                <v-col>
                                                    <p class="tw-text-base tw-font-semibold tw-text-black">{{ stage.id }}</p>
                                                </v-col>
                                                <v-col>
                                                    <p class="tw-text-base tw-font-semibold tw-text-black">{{ stage.startDate }}</p>
                                                </v-col>
                                                <v-col>
                                                    <p class="tw-text-base tw-font-semibold tw-text-black">{{ stage.endDate }}</p>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template v-slot:append>
                                            <v-btn
                                                @click="console.log('Register Values')"
                                                elevation="0"
                                                density="compact"
                                                class="tw-h-10"
                                                rounded="lg"
                                                color="primary">
                                                <p class="tw-text-white">
                                                    {{ $t('industry.processes.labels.registerValues') }}
                                                </p>
                                            </v-btn>
                                        </template>
                                    </v-list-item>
                                </template>
                                <v-list-item
                                    v-for="(indicator, j) in stage.indicators"
                                    :key="j">
                                    <template v-slot:prepend="{ isActive }">
                                        <v-checkbox-btn
                                            color="primary"
                                            class="tw-mr-4"
                                            :model-value="isActive"></v-checkbox-btn>
                                    </template>
                                    <template v-slot:title>
                                        <v-row>
                                            <v-col>
                                                <p class="tw-text-sm tw-font-medium tw-text-black">{{ indicator.name }}</p>
                                            </v-col>
                                            <v-col>
                                                <p class="tw-text-sm tw-font-medium tw-text-black">{{ indicator.value }}</p>
                                            </v-col>
                                            <v-col> </v-col>
                                            <v-col> </v-col>
                                            <v-col> </v-col>
                                        </v-row>
                                    </template>
                                </v-list-item>
                            </v-list-group>
                        </v-list-group>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('industry.title'),
            },
            {
                title: $t('industry.processes.title'),
            },
        ];
    });

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const processes = ref([
        {
            name: 'Processo 1',
            stages: [
                {
                    id: 1357,
                    stage: '1° Fase',
                    asset: 'Máquina 1',
                    expanded: false,
                    startDate: '20-05-2024',
                    endDate: '30-05-2024',
                    indicators: [
                        {
                            name: 'Indicador 1',
                            value: '10',
                        },
                        {
                            name: 'Indicador 2',
                            value: '20',
                        },
                        {
                            name: 'Indicador 3',
                            value: '30',
                        },
                    ],
                },
                {
                    id: 1358,
                    stage: '2° Fase',
                    asset: 'Máquina 2',
                    expanded: false,

                    startDate: '01-06-2024',
                    endDate: '10-06-2024',
                    indicators: [
                        {
                            name: 'Indicador 1',
                            value: '20',
                        },
                        {
                            name: 'Indicador 2',
                            value: '30',
                        },
                        {
                            name: 'Indicador 3',
                            value: '40',
                        },
                    ],
                },
            ],
        },
        {
            name: 'Processo 2',
            stages: [
                {
                    id: 1357,
                    stage: '1° Fase',
                    asset: 'Máquina 1',
                    expanded: false,

                    startDate: '20-05-2024',
                    endDate: '30-05-2024',
                    indicators: [
                        {
                            name: 'Indicador 1',
                            value: '30',
                        },
                    ],
                },
            ],
        },
    ]);
</script>
<style></style>
