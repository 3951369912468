<template>
    <v-app>
        <router-view />
    </v-app>
</template>

<script setup lang="ts">
    import { useAuth } from '@/composables/useAuth';
    import { useColor } from '@/composables/useColor';
    import { onMounted } from 'vue';
    import { useTheme } from 'vuetify/lib/framework.mjs';
    import { useAuthStore } from './store/auth';
    import _, { create } from 'lodash';

    //handle color change on theme (based on company primary color)
    const primaryColor = useAuthStore().getActiveCompany?.primaryColor || '#1D70B7';

    const colors = {
        primary: primaryColor,
    };

    const $theme = useTheme();

    onMounted(() => {
        $theme.themes.value.light.colors = _.merge($theme.themes.value.light.colors, useColor(colors));
    });

    useAuth().checkAuth();
</script>
<style></style>
